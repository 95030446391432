<template>
  <!-- <div class="container" id="paymentPlans"> -->
  <div class="row justify-content-center  " v-if="courseDetail && courseDetail.Packages">
    <div class=" col-sm-8 col-md-6 col-lg-4 col-xl-3  plan-card-col mb-4"
      v-for="(coursePackage, index) in courseDetail.Packages" :key="index">
      <div class="paymentBox sigleCart"
        :class="[isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId) ? 'selected' : '', courseDetail.EnableTrial ? 'freetrail' : '']">

        <div class="cardHead text-center ">
          <div class="mainTitle">{{ coursePackage.Title }}</div>
          <div class="PackageDesc" v-if="coursePackage.PaymentPlans.length">
            <div>
              <h3>
                <span v-if="getMinValue(coursePackage).Price.Format">{{ getMinValue(coursePackage).Price.Format.slice(0,
                  -3) }} </span><small class="d-block">{{ getMinValue(coursePackage).Title }}</small><small> {{
                    getMinValue(coursePackage).BillingPeriod.Frequency }} {{
                    getMinValue(coursePackage).BillingPeriod.Period.Name }}</small>
              </h3>
            </div>

          </div>
        </div>
        <div class="cart-btns">
          <button @click="AddToTrial(coursePackage)" class="btn cart-btn" v-if="courseDetail.EnableTrial">Free
            Trial</button>
          <div class="btn-loader d-flex justify-content-center mt10" v-if="isServer === 'server'">
            <span class="skeleton-loader"></span>
          </div>
          <div v-else-if="!isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId)">
            <button @click="AddToCart(coursePackage)" class="btn secondary-btn">Add To Cart</button>
          </div>
          <div v-else>
            <button class="btn cart-btn" @click="goToCart">
              <i class="fa fa-check-circle mr-1"></i>
              Go to cart
            </button>
            <div class="changePlan" v-if="coursePackage.PaymentPlans.length > 1" @click="AddToCart(coursePackage)">
              Change Plan</div>
          </div>
        </div>
        <div :class="coursePackage.Features.length ? 'cardFooter' : ''">
          <div class="radioOptinos">
            <div class="mainTitle">Payment Plans</div>
            <div class="d-flex flex-column">
              <div class="paymentplanoption d-flex justify-content-between"
                v-for="element in coursePackage.PaymentPlans" :key="element.SOSPaymentPlanId"
                :class="selectedSOS($decryptedRouteParams.sosId) && selectedSOS($decryptedRouteParams.sosId).SOSPaymentPlanId === element.SOSPaymentPlanId ? 'font-weight-bold' : ''">
                <span class="titleWrap">
                  {{ element.Title }}
                  <div class="package-duration">({{ element.BillingPeriod.Frequency }} {{
                    element.BillingPeriod.Period.Name
                  }})</div>
                </span><strong v-if="element.Price.Format">{{ element.Price.Format.slice(0, -3) }} <s
                    v-if="element.PriceStrikethrough.Format" class="d-block strikethrough">{{
                      element.PriceStrikethrough.Format.slice(0, -3) }}</s>
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ul>
            <li v-for="feature in coursePackage.SalientFeatures" :key="feature.Description">{{ feature.Description }}
            </li>
          </ul>
        </div>
        <div>
          <a class="all-features-btn " @click="scrollToFeatures">See all Features</a>
        </div>
        <!-- <coursePackage :model="coursePackage" /> -->

      </div>
    </div>

  </div>
  <!-- </div> -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, checkout as checkoutStoreKeys, cart as cartStoreKeys } from "../store/keys";
import coursePackage from "./packages.vue";
import { appConstants } from "../common/constants";
export default {
  components: {
    coursePackage,
  },
  data() {
    return {
      isServer: process.env.VUE_ENV,
    };
  },
  computed: {
    ...mapGetters(courseDetailStoreKeys.namespace, courseDetailStoreKeys.getters),
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
  },
  mounted() { },
  methods: {
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    async AddToCart(obj) {
      this.$emit("choosePlan", obj, appConstants.enums.PaymentOption.PaymentPlan);
    },
    goToCart() {
      this.$router.push({ name: "cart" }).catch(() => { });
    },
    AddToTrial(coursePackage) {
      this.$emit("choosePlan", coursePackage, appConstants.enums.PaymentOption.Trial);
    },
    scrollToFeatures(coursePackage) {
      this.$emit("scrollFeature");
    },
    getMinValue(model) {
      if (model && model.PaymentPlans.length) {
        return model.PaymentPlans.reduce((previous, current) => {
          return current.Price.Value < previous.Price.Value ? current : previous;
        });
      }
    },
  },
};
</script>
